// Async Dependency Loader - no static imports
export const isModule = true;

(async () => {
    const specialRoutes = [`/test`];
    const { pathname } = window.location;
    const isSpecialRoute = specialRoutes.some(x => pathname.startsWith(x));

    const loadReact = !isSpecialRoute;
    if (loadReact) {
        await import(`./index-react`);
        return;
    }

    // TESTING: Load a non-react display
    const root = document.querySelector(`#root`);
    if (!root) {
        return;
    }

    root.innerHTML = `This is a test!`;
})();
